import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const signUp = async (username, password, email) => {
    const sgnUp = await axios.post(`${backendUrl}/sign_up?username=${username}&password=${password}&email=${email}`);

    const lgin = await axios.post(`${backendUrl}/login?username=${username}&password=${password}`);
    localStorage.setItem("username", username);
    localStorage.setItem("password", password);
    localStorage.setItem("_id", lgin.data._id);
    return sgnUp;
}