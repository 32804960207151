import { Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { convertDecimalToAmerican, getComparator } from '../EvScanner';
import { useMemo, useState } from 'react';
import { LogoGenerator } from '../../LogoGenerator/LogoGenerator';
import { BetPrice } from '../../BetPrice/BetPrice';
import { LineChart } from '@mui/x-charts';
import './Sidebar.css';
import Select from 'react-select';
import { animatedComponents } from '../FiltersBar/QueryRow';

function nextBiggest(arr) {
	let max = -Infinity,
		result = -Infinity;

	for (const value of arr) {
		const nr = Number(value);

		if (nr > max) {
			[result, max] = [max, nr]; // save previous max
		} else if (nr < max && nr > result) {
			result = nr; // new second biggest
		}
	}

	return result;
}
export const Sidebar = (props) => {
	const { data, oddsHistoryData, selectedOddsHistorySportsbook, selectedDumbBook, setLineCharts, scrollTo } = props;
	// console.log('data: ', data);
	const { selectedOddsHistoryXAxis, selectedOddsHistoryYAxis, selectedOddsHistoryXAxisUnder, selectedOddsHistoryYAxisUnder } = oddsHistoryData;
	const [isModalOpen, setIsModalOpen] = useState(false);

	const allSportsbooks = data.map((e) => {
		return {
			value: e.sportsbook,
			label: e.sportsbook,
		};
	});

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('sportsbook');
	const visibleRows = useMemo(() => data?.sort(getComparator(order, orderBy), [data, order, orderBy]));

	try {
		if (typeof selectedOddsHistoryXAxis?.[0] === 'object') {
			const averageOver = visibleRows.filter((e) => e.bet_price_over).reduce((total, next) => total + next.bet_price_over, 0) / visibleRows.length;
			const highOver = Math.max(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));

			const averageUnder = visibleRows.filter((e) => e.bet_price_under).reduce((total, next) => total + next.bet_price_under, 0) / visibleRows.length;
			const highUnder = Math.max(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));
			const sportsbookNames = props.selectedBooksIncludedInGraph;

			const secondHighOver = nextBiggest(visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			const secondHighUnder = nextBiggest(visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			return (
				<div style={{ position: 'sticky', top: 0, borderRadius: '16px', overflowY: "scroll", height: "82vh"  }}>
					<div
						style={{
							backgroundColor: '#0f1b33',
							color: 'white',
							borderTopLeftRadius: '15px',
							borderTopRightRadius: '15px',
							textAlign: 'center',
							paddingTop: '4px',
							fontWeight: 'bold',
							position: 'sticky',
							top: 0,
						}}>
						{data[0].selection} {data[0].selection_points} {data[0].market}
					</div>
					<Table
						style={{ backgroundColor: '#f5f5f5', color: '#0f1b33', borderRadius: '15px' }}
						size="small"
						sx={{
							'& .MuiTableCell-root': {
								padding: '1px',
							},
						}}>
						<TableHead style={{ backgroundColor: '#0f1b33'}}>
							<TableRow style={{  }}>
								<TableCell>
									<TableSortLabel
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}></TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_over' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_over');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										<div style={{ textAlign: 'center' }}>Over</div>
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_under' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_under');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Under
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell style={{ maxWidth: '12px' }}>
									<Tooltip title="View all books' histories">
										<div
											style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center', cursor: 'pointer' }}
											onClick={() => {
												props.setSelectedOddsHistorySportsbook(sportsbookNames);
												props.setSelectedBooksIncludedInGraph([]);
												setIsModalOpen(true);
												scrollTo()
											}}>
											Average
										</div>
									</Tooltip>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<div style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center' }}>High</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							{visibleRows?.map((row) => {
								let backgroundColorOver = '';
								let backgroundColorUnder = '';

								if (row.bet_price_over === highOver) {
									backgroundColorOver = '#3df57a';
								} else if (row.bet_price_over === secondHighOver) {
									backgroundColorOver = '#afd7c5';
								}
								if (row.bet_price_under === highUnder) {
									backgroundColorUnder = '#3df57a';
								} else if (row.bet_price_under === secondHighUnder) {
									backgroundColorUnder = '#afd7c5';
								}

								return (
									<TableRow style={{ backgroundColor: row.sportsbook === selectedDumbBook ? '#dd8d13' : '' }}>
										<TableCell>
											<div
												style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
												onClick={() => {
													props.setSelectedOddsHistorySportsbook(row.sportsbook);
													setIsModalOpen(true);
												}}
												className="logo">
												<LogoGenerator sportsbook={row.sportsbook} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorOver, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_over).toFixed(0)} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorUnder, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_under).toFixed(0)} />
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			);
		} else {
			const averageOver = visibleRows.filter((e) => e.bet_price_over).reduce((total, next) => total + next.bet_price_over, 0) / visibleRows.length;
			const highOver = Math.max(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			// const lowOver = Math.min(...visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));

			const averageUnder = visibleRows.filter((e) => e.bet_price_under).reduce((total, next) => total + next.bet_price_under, 0) / visibleRows.length;
			const highUnder = Math.max(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));
			// const lowUnder = Math.min(...visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			const secondHighOver = nextBiggest(visibleRows.filter((e) => e.bet_price_over)?.map((o) => o.bet_price_over));
			const secondHighUnder = nextBiggest(visibleRows.filter((e) => e.bet_price_under)?.map((o) => o.bet_price_under));

			const sportsbookNames = data?.map((oddsHistory) => oddsHistory.sportsbook);

			return (
				<div style={{ position: 'sticky', top: 0, borderRadius: '16px', overflowY: "scroll", height: "82vh" }}>
					<div
						style={{
							backgroundColor: '#0f1b33',
							color: 'white',
							borderTopLeftRadius: '15px',
							borderTopRightRadius: '15px',
							textAlign: 'center',
							paddingTop: '4px',
							fontWeight: 'bold',
							position: 'sticky',
							top: 0,
						}}>
						{data[0].selection} {data[0].selection_points} {data[0].market}
					</div>
					<Table
						style={{ backgroundColor: '#f5f5f5', color: '#0f1b33', borderRadius: '15px' }}
						size="small"
						sx={{
							'& .MuiTableCell-root': {
								padding: '1px',
							},
						}}>
						<TableHead style={{ backgroundColor: '#0f1b33', position: 'sticky', top: 'auto' }}>
							<TableRow style={{position: "sticky", top: 25,backgroundColor: '#0f1b33'}}>
								<TableCell>
									<TableSortLabel
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}></TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_over' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_over');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										<div style={{ textAlign: 'center' }}>Over</div>
									</TableSortLabel>
								</TableCell>
								<TableCell>
									<TableSortLabel
										style={{ backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '8px' }}
										direction={orderBy === 'bet_price_under' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price_under');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Under
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell style={{ maxWidth: '10px' }}>
									<Tooltip title="View all books' histories">
										<div
											style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center', cursor: 'pointer' }}
											onClick={() => {
												props.setSelectedOddsHistorySportsbook(sportsbookNames);
												props.setSelectedBooksIncludedInGraph([]);
												setIsModalOpen(true);
												setLineCharts(true);
												scrollTo()
												// console.log(selectedOddsHistoryYAxisMapped, selectedOddsHistoryXAxis);
												// setLineCharts(
												// 	<div
												// 		style={{
												// 			display: 'flex',
												// 			backgroundColor: 'white',
												// 			marginTop: '10vh',
												// 			marginLeft: '4rem',
												// 			marginRight: '4rem',
												// 			borderRadius: '10px',
												// 			paddingTop: '10px',
												// 		}}>
												// 		<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
												// 			<div style={{ display: 'flex' }}>
												// 				<LogoGenerator sportsbook={props.selectedOddsHistorySportsbook} />
												// 				<div style={{ marginLeft: '1rem' }}>
												// 					{props.data[0].selection} over {props.data[0].selection_points} {props.data[0].market}
												// 				</div>
												// 			</div>
												// 			<LineChart
												// 				xAxis={[
												// 					{
												// 						// @ts-ignore
												// 						data: selectedOddsHistoryXAxis,
												// 						label: 'Minutes ago',
												// 						valueFormatter: (value) => value.toString(),
												// 						reverse: true,
												// 						// hideTooltip: true,
												// 					},
												// 				]}
												// 				series={[
												// 					{
												// 						data: selectedOddsHistoryYAxisMapped,
												// 						color: '#0f1b33',
												// 						label: 'Price',
												// 						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
												// 						curve: 'linear',
												// 					},
												// 				]}
												// 				axisHighlight={{ x: 'none', y: 'none' }}
												// 				tooltip={{ trigger: 'item' }}
												// 				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
												// 				width={500}
												// 				height={300}
												// 				slotProps={{ legend: { hidden: true } }}
												// 				sx={{
												// 					'& .MuiChartsAxis-label': {
												// 						// padding: "10px !important",
												// 						// fill: "white !important",
												// 						marginRight: '20px',
												// 					},
												// 					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
												// 						transform: 'translateX(-12px) !important',
												// 					},
												// 				}}
												// 			/>
												// 		</div>
												// 		<div style={{ backgroundColor: 'white', width: '40%', borderRadius: '10px' }}>
												// 			<div style={{ display: 'flex' }}>
												// 				<div
												// 					style={{
												// 						marginRight: '1rem',
												// 					}}>
												// 					<LogoGenerator sportsbook={props.selectedOddsHistorySportsbook} />
												// 				</div>
												// 				{props.data[0].selection} under {props.data[0].selection_points} {props.data[0].market}
												// 			</div>
												// 			<LineChart
												// 				xAxis={[
												// 					{
												// 						// @ts-ignore
												// 						data: selectedOddsHistoryXAxisUnder,
												// 						label: 'Minutes ago',
												// 						valueFormatter: (value) => value.toString(),
												// 						reverse: true,
												// 						// hideTooltip: true,
												// 					},
												// 				]}
												// 				series={[
												// 					{
												// 						data: selectedOddsHistoryYAxisUnderMapped,
												// 						color: '#0f1b33',
												// 						label: 'Price',
												// 						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
												// 						curve: 'linear',
												// 					},
												// 				]}
												// 				axisHighlight={{ x: 'none', y: 'none' }}
												// 				tooltip={{ trigger: 'item' }}
												// 				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
												// 				width={500}
												// 				height={300}
												// 				slotProps={{ legend: { hidden: true } }}
												// 				sx={{
												// 					'& .MuiChartsAxis-label': {
												// 						// padding: "10px !important",
												// 						// fill: "white !important",
												// 						marginRight: '20px',
												// 					},
												// 					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
												// 						transform: 'translateX(-12px) !important',
												// 					},
												// 				}}
												// 			/>
												// 		</div>
												// 	</div>
												// );
											}}>
											Average
										</div>
									</Tooltip>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(averageUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<div style={{ backgroundColor: '#2c2b30', padding: '8px', width: '60px', borderRadius: '8px', color: 'white', textAlign: 'center' }}>High</div>
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highOver).toFixed(0)} />
								</TableCell>
								<TableCell>
									<BetPrice price={convertDecimalToAmerican(highUnder).toFixed(0)} />
								</TableCell>
							</TableRow>
							{visibleRows?.map((row) => {
								let backgroundColorOver = '';
								let backgroundColorUnder = '';

								if (row.bet_price_over === highOver) {
									backgroundColorOver = '#3df57a';
								} else if (row.bet_price_over === secondHighOver) {
									backgroundColorOver = '#afd7c5';
								}
								if (row.bet_price_under === highUnder) {
									backgroundColorUnder = '#3df57a';
								} else if (row.bet_price_under === secondHighUnder) {
									backgroundColorUnder = '#afd7c5';
								}
								return (
									<TableRow style={{ backgroundColor: row.sportsbook === selectedDumbBook ? '#dd8d13' : '' }}>
										<TableCell>
											<div
												style={{ cursor: 'pointer', width: 'min-content', borderRadius: '12px', padding: '2px' }}
												onClick={() => {
													setIsModalOpen(true);
													props.setSelectedOddsHistorySportsbook(row.sportsbook);

													setLineCharts(true);
													scrollTo()
													// setLineCharts(
													// 	<div
													// 		style={{
													// 			display: 'flex',
													// 			backgroundColor: 'white',
													// 			marginTop: '10vh',
													// 			marginLeft: '4rem',
													// 			marginRight: '4rem',
													// 			borderRadius: '10px',
													// 			paddingTop: '10px',
													// 		}}>
													// 		<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
													// 			<div style={{ display: 'flex' }}>
													// 				<LogoGenerator sportsbook={props.selectedOddsHistorySportsbook} />
													// 				<div style={{ marginLeft: '1rem' }}>
													// 					{props.data[0].selection} over {props.data[0].selection_points} {props.data[0].market}
													// 				</div>
													// 			</div>
													// 			<LineChart
													// 				xAxis={[
													// 					{
													// 						// @ts-ignore
													// 						data: selectedOddsHistoryXAxis,
													// 						label: 'Minutes ago',
													// 						valueFormatter: (value) => value.toString(),
													// 						reverse: true,
													// 						// hideTooltip: true,
													// 					},
													// 				]}
													// 				series={[
													// 					{
													// 						data: selectedOddsHistoryYAxisMapped,
													// 						color: '#0f1b33',
													// 						label: 'Price',
													// 						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
													// 						curve: 'linear',
													// 					},
													// 				]}
													// 				axisHighlight={{ x: 'none', y: 'none' }}
													// 				tooltip={{ trigger: 'item' }}
													// 				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
													// 				width={500}
													// 				height={300}
													// 				slotProps={{ legend: { hidden: true } }}
													// 				sx={{
													// 					'& .MuiChartsAxis-label': {
													// 						// padding: "10px !important",
													// 						// fill: "white !important",
													// 						marginRight: '20px',
													// 					},
													// 					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
													// 						transform: 'translateX(-12px) !important',
													// 					},
													// 				}}
													// 			/>
													// 		</div>
													// 		<div style={{ backgroundColor: 'white', width: '40%', borderRadius: '10px' }}>
													// 			<div style={{ display: 'flex' }}>
													// 				<div
													// 					style={{
													// 						marginRight: '1rem',
													// 					}}>
													// 					<LogoGenerator sportsbook={props.selectedOddsHistorySportsbook} />
													// 				</div>
													// 				{props.data[0].selection} under {props.data[0].selection_points} {props.data[0].market}
													// 			</div>
													// 			<LineChart
													// 				xAxis={[
													// 					{
													// 						// @ts-ignore
													// 						data: selectedOddsHistoryXAxisUnder,
													// 						label: 'Minutes ago',
													// 						valueFormatter: (value) => value.toString(),
													// 						reverse: true,
													// 						// hideTooltip: true,
													// 					},
													// 				]}
													// 				series={[
													// 					{
													// 						data: selectedOddsHistoryYAxisUnderMapped,
													// 						color: '#0f1b33',
													// 						label: 'Price',
													// 						valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
													// 						curve: 'linear',
													// 					},
													// 				]}
													// 				axisHighlight={{ x: 'none', y: 'none' }}
													// 				tooltip={{ trigger: 'item' }}
													// 				yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
													// 				width={500}
													// 				height={300}
													// 				slotProps={{ legend: { hidden: true } }}
													// 				sx={{
													// 					'& .MuiChartsAxis-label': {
													// 						// padding: "10px !important",
													// 						// fill: "white !important",
													// 						marginRight: '20px',
													// 					},
													// 					'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
													// 						transform: 'translateX(-12px) !important',
													// 					},
													// 				}}
													// 			/>
													// 		</div>
													// 	</div>
													// );
												}}
												className="logo">
												<LogoGenerator sportsbook={row.sportsbook} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorOver, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_over).toFixed(0)} />
											</div>
										</TableCell>
										<TableCell>
											<div style={{ backgroundColor: backgroundColorUnder, width: 'min-content', borderRadius: '12px' }}>
												<BetPrice price={convertDecimalToAmerican(row.bet_price_under).toFixed(0)} />
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			);
		}
	} catch (e) {
		console.log(e);
		return <></>;
	}
};
