import vblogo from '../../vb_logo.svg';
import menuicon from '../../menu_icon.svg';
import question_mark_icon from '../../question_mark_icon.svg';
import profile_icon from '../../profile_icon.svg';
import { useState } from 'react';
import { Button } from '@mui/material';

export const TopBar = (props) => {
	const { username } = props;
	const [isProfileOpen, setIsProfileOpen] = useState(false);

	return (
		<div>
			<div style={{ backgroundColor: '#0f1b33', height: '60px', display: 'flex', flexDirection: 'row' }}>
				<img style={{ marginLeft: '1rem', cursor: 'pointer' }} width={40} src={vblogo} />
				<div style={{ color: 'white', display: 'flex', flexDirection: 'row' }}>
					{/* <div style={{ alignContent: "center", marginLeft: '4rem', cursor: 'pointer' }}>
                    <img src={menuicon} />
                </div> */}

					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						EV Scanner
					</div>

					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						Sportsbook Screener
					</div>
					<div className="button" style={{ alignContent: 'center', marginLeft: '2rem', cursor: 'pointer' }}>
						Future Updates
					</div>
				</div>
				<div style={{ marginLeft: 'auto', marginRight: '1rem', display: 'flex' }}>
					<div style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto' }}>{username}</div>

					<div>
						<img style={{ cursor: 'pointer', marginRight: '0.5rem' }} src={question_mark_icon} className="button" />
						<img
							height={40}
							style={{ cursor: 'pointer' }}
							src={profile_icon}
							className="button"
							onClick={() => {
								setIsProfileOpen(!isProfileOpen);
							}}
						/>
					</div>
				</div>
			</div>
			<div style={{ height: '6px', backgroundColor: '#dc8d16' }} />
			{isProfileOpen && props.isSignedIn && (
				<div>
					<div style={{ marginLeft: 'auto', width: '80px', marginTop: '-15px', backgroundColor: '#0f1b33', borderRadius: '12px' }}>
						<Button
							onClick={() => {
								localStorage.clear();
								setIsProfileOpen(false);
								props.setIsSignedIn(false);
							}}>
							Logout
						</Button>
					</div>
				</div>
			)}
			{!isProfileOpen && props.isSignedIn && <div style={{ height: '21.5px' }}></div>}
		</div>
	);
};
