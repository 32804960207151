import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const savePreferences = async (username, password, queryRows, queryName) => {
    const queryRowsCopy = JSON.parse(JSON.stringify(queryRows));
    const id = queryRowsCopy[0].id
    for (const queryRow of queryRowsCopy) {
        queryRow.minMaxEvFilterOpen = false
        queryRow.minMaxOddsFilterOpen = false
        queryRow.name = queryName
        queryRow.id = id
    }

    const res = (await axios.post(`${backendUrl}/preferences?username=${username}&password=${password}&queryName=${queryName}`, queryRowsCopy)).data;
    
    return res;
}