import DatePicker from "react-datepicker";
import './DatePicker.css'

import "react-datepicker/dist/react-datepicker.css";

export const DatePickerComponent = (props) => {
    return (<div style={{ marginRight: "1rem", marginTop: "0.5rem" }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '3.4rem', maxWidth: "180px" }}>
            <DatePicker wrapperClassName="customDatePickerWidth" selected={new Date(props.queryRow.date)} onChange={(e) => {
                const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
                queryRowsCopy.forEach((row, index) => {
                    if (index === props.index) {
                        row.date = new Date(e)
                    }
                })
                props.setQueryRows(queryRowsCopy)
            }} />
            Date until
        </div>

    </div>)
}