import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const runEvScan = async (username, password, queryRows, isLive) => {
    const queryRowsCopy = JSON.parse(JSON.stringify(queryRows))


    queryRowsCopy?.forEach(queryRow => {
        const books = []
        queryRow.book?.forEach(bk => {
            books.push(bk.value)
        })
        queryRow.book = books

        const sharps = []
        queryRow.sharp?.forEach(bk => {
            sharps.push(bk.value)
        })
        queryRow.sharp = sharps

        const minMax = []
        queryRow.minMaxOdds?.forEach(m => {
            minMax.push(Number(m))
        });
        queryRow.minMaxOdds = minMax;
    })

    const evResponse = (await axios.get(`${backendUrl}/evScreener?queryRows=${JSON.stringify(queryRowsCopy)}&username=${username}&password=${password}&is_live=${isLive}`)).data;

    const returnArr = []
    for (let key in evResponse) {
        try {
            evResponse[key].id = evResponse[key].outcome_id_normalized
            evResponse[key].bet_type = evResponse[key].outcome_id_normalized.split(':')[1];
            const formattedPriceOverSharp = evResponse[key].bet_price_over_sharp > 0 ? `+${evResponse[key].bet_price_over_sharp?.toFixed(0)}` : evResponse[key].bet_price_over_sharp?.toFixed(0)
            const formattedPriceUnderSharp = evResponse[key].bet_price_under_sharp > 0 ? `+${evResponse[key].bet_price_under_sharp.toFixed(0)}` : evResponse[key].bet_price_under_sharp?.toFixed(0)

            if (evResponse[key].ev_over > evResponse[key].ev_under) {
                evResponse[key].bet_price = evResponse[key].bet_price_over_dumb.toFixed(0) > 0 ? `+${evResponse[key].bet_price_over_dumb.toFixed(0)}` : evResponse[key].bet_price_over_dumb.toFixed(0)
                evResponse[key].sharp_prices = `${formattedPriceOverSharp} / ${formattedPriceUnderSharp}`
                evResponse[key].quarter_kelly = evResponse[key].quarter_kelly_over
                evResponse[key].ev = evResponse[key].ev_over

                const splitId = evResponse[key].outcome_id_normalized.split(':')
                evResponse[key].bet_name = `${splitId[2]} over ${splitId[3]}`;
            } else {
                evResponse[key].bet_price = evResponse[key].bet_price_under_dumb.toFixed(0) > 0 ? `+${evResponse[key].bet_price_under_dumb.toFixed(0)}` : evResponse[key].bet_price_under_dumb.toFixed(0)
                evResponse[key].sharp_prices = `${formattedPriceUnderSharp} / ${formattedPriceOverSharp}`
                evResponse[key].quarter_kelly = evResponse[key].quarter_kelly_under
                evResponse[key].ev = evResponse[key].ev_under

                const splitId = evResponse[key].outcome_id_normalized.split(':')
                evResponse[key].bet_name = `${splitId[2]} under ${splitId[3]}`;
            }              

            returnArr.push(evResponse[key])
        } catch (e) {

        }

    }

    return returnArr.sort((a, b) => b.ev - a.ev)
}