export const MinMaxEvComponent = (props) => {
    return (<div style={{ cursor: 'pointer', border: props.queryRow.minMaxEvFilterOpen ? "1px solid" : "1px solid #cccccc", borderRadius: "6px", width: '199px', backgroundColor: "#ffffff", height: props.queryRow.minMaxEvFilterOpen ? "" : "38px", marginTop: "0.5rem"}}>
          <div onClick={() => {
            const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
            queryRowsCopy.forEach((row, index) => {
                if (index === props.index) {
                    row.minMaxEvFilterOpen = !row.minMaxEvFilterOpen
                }
            })
            props.setQueryRows(queryRowsCopy)
          }} style={{backgroundColor: props.queryRow.minMaxEvFilterOpen ? "#0f1b33" : "", color: props.queryRow.minMaxEvFilterOpen ? "white": "", borderTopLeftRadius: "6px", borderTopRightRadius: "6px", paddingLeft: "8px", paddingTop: "8px"}}>
            Min/Max EV: <span style={{fontSize: "12px"}}>{ `[${props.queryRow.minMaxEv[0]}%, ${props.queryRow.minMaxEv[1]}%]`}</span>
          </div>
          {
            props.queryRow.minMaxEvFilterOpen && <div style={{padding: "0.25rem"}}>
              <div style={{ display: 'grid', gridTemplateColumns: "auto auto" }}>
                <div>
                  <p>Min EV: </p>

                  <p>Max EV: </p>
                </div>
                <div>
                  <div style={{marginTop: '1rem'}}>
                    <input style={{ width: "35px", marginLeft: '0.4rem' }} onChange={(e) => {
                        const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
                        queryRowsCopy.forEach((row, index) => {
                            if (index === props.index) {
                                row.minMaxEv = [e.target.value < 100 && e.target.value >= 0 ? Number(e.target.value) : 0, row.minMaxEv[1]]
                            }
                        })
                        props.setQueryRows(queryRowsCopy)
                    }} value={props.queryRow.minMaxEv[0]} />%
                  </div>
                  <div style={{marginTop: '0.8rem'}}>
                    <input style={{ width: "35px", marginLeft: '0.4rem' }} onChange={(e) => {
                        const queryRowsCopy = JSON.parse(JSON.stringify(props.queryRows))
                        queryRowsCopy.forEach((row, index) => {
                            if (index === props.index) {
                                row.minMaxEv = [row.minMaxEv[0], e.target.value <= 100 && e.target.value >= 0 ? Number(e.target.value) : 0]
                            }
                        })
                        props.setQueryRows(queryRowsCopy)
                    }} value={props.queryRow.minMaxEv[1]} />%
                  </div>
                </div>
              </div>
            </div>
          }
        </div>)
}