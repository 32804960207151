import { useEffect, useMemo, useState } from 'react';
import { EvScannerBar } from './EvScannerBar';
import { FiltersBar } from './FiltersBar/FiltersBar';
import { QueryBar } from './QueryBar';
import { getCommonData } from '../../api/getCommonData';
import { retrieveUsernameAndPassword } from '../../api/retrieveUsernameAndPassword';
import { runEvScan } from '../../api/runEvScan';
import { Table, TableHead, TableBody, TableCell, TableRow, Tooltip, Collapse, Box, CircularProgress, TableContainer, Alert, TableSortLabel, Modal } from '@mui/material';
import { getRelatedOdds } from '../../api/getRelatedOdds';
import { GridLoader } from 'react-spinners';
import { getGamesData } from '../../api/getGamesData';
import { getLinksData } from '../../api/getLinksData';
import { savePreferences } from '../../api/saveSettings';
import { LogoGenerator } from '../LogoGenerator/LogoGenerator';
import LinkIcon from '@mui/icons-material/Link';
import './EvScanner.css';
import { NotificationsBar } from './FiltersBar/NotificationsBar';
import { Sidebar } from './Sidebar/Sidebar';
import { BetPrice } from '../BetPrice/BetPrice';
import { LineChart } from '@mui/x-charts';
import { animatedComponents } from './FiltersBar/QueryRow';
import Select from 'react-select';
import { NotificationsModal } from './NotificationsModal/NotificationsModal';
import { verifyDiscord } from '../../api/verifyDiscord';
import { toggleDiscordNotifications } from '../../api/toggleDiscordNotifications';
import { saveNotificationPreferences } from '../../api/saveNotificationSettings';
import {
	Link,
	Element,
	Events,
	animateScroll as scroll,
	scroller,
} from "react-scroll";


export const whiteShaded = '#F5F5F5';

export const white = '#FFFFFF';

export const grey = '#ADADAD';

export const lightGreen = '#31E000';

export const darkGreen = '#5ABE49';

export const topComparableSportsbooks = ['bet365', 'Draftkings', 'Caesars', 'theScore', 'Pinnacle', 'FanDuel', 'BetMGM'];

function descendingComparator(a, b, orderBy) {
	// console.log('a: ', a, orderBy, b[orderBy], a[orderBy], b[orderBy] < a[orderBy]);

	if (orderBy === "start_date") {
		if (b.game?.[orderBy] < a.game?.[orderBy]) {
			return -1;
		}
		if (b.game?.[orderBy] > a.game?.[orderBy]) {
			return 1;
		}
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

const getLineMovementDirection = (historical_data) => {
	// console.log('historical_data: ', historical_data);
	try {
		const finalDataPoint = historical_data[historical_data.length - 1];
		const secondFinalDataPoint = historical_data[historical_data.length - 2];

		if (!secondFinalDataPoint || !finalDataPoint) {
			// no movement
			return 0;
		} else if (finalDataPoint.price > secondFinalDataPoint.price) {
			// direction is up
			return 1;
		} else if (finalDataPoint.price < secondFinalDataPoint.price) {
			// direction is down
			return -1;
		} else {
			// no movement
			return 0;
		}
	} catch (e) {
		// console.log(e, 'no movement');
		return 0;
	}
};

export function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export const formatPrice = (price) => {
	if (Number(price) > 0) {
		return <>&nbsp;+{price || ''}</>;
	} else {
		return `${price || ''}`;
	}
};

export const convertAmericanToImpliedProbability = (americanOdds) => {
	if (americanOdds > 0) {
		return 100 / (americanOdds + 100);
	} else {
		return -americanOdds / (-americanOdds + 100);
	}
};

export const convertImpliedProbabilityToAmerican = (impliedProbabilityOdds) => {
	if (impliedProbabilityOdds >= 50) {
		return 100 / (impliedProbabilityOdds / 100) - 100;
	} else {
		return -(impliedProbabilityOdds / (1 - impliedProbabilityOdds / 100));
	}
};

export const convertDecimalToAmerican = (decimalOdds) => {
	if (decimalOdds >= 2) {
		return (decimalOdds - 1) * 100;
	} else {
		return -100 / (decimalOdds - 1);
	}
};

export const convertAmericanToDecimal = (americanOdds) => {
	if (americanOdds > 0) {
		return 1 + americanOdds / 100;
	} else {
		return 1 - 100 / americanOdds;
	}
};

export const minutesSince = (date) => {
	//@ts-ignore
	const seconds = Math.floor((new Date() - date) / 1000);
	// const interval = ((seconds / 60) / 60).toFixed(1);
	const interval = seconds / 60;
	// return Math.floor(interval) + " minutes";
	return Math.floor(interval);
};

export const EvScanner = (props) => {
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [isNotificationFiltersOpen, setIsNotificationFiltersOpen] = useState(false);

	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

	const { username, password } = retrieveUsernameAndPassword();
	const [filterOptions, setFilterOptions] = useState();
	const [queryRows, setQueryRows] = useState([]);
	const [queries, setQueries] = useState([]);
	const [selectedQuery, setSelectedQuery] = useState();

	const [evData, setEvData] = useState([]);
	// console.log('evData: ', evData);

	const [bankroll, setBankroll] = useState(50000);

	const [isLoading, setIsLoading] = useState(false);
	const [isGamesLoading, setIsGamesLoading] = useState(false);

	const [selectedRow, setSelectedRow] = useState();
	const [selectedRowData, setSelectedRowData] = useState([]);
	const [isModalLoading, setIsModalLoading] = useState(false);

	// const [isOddsHistoryOpen, setIsOddsHistoryOpen] = useState(false);
	const [selectedOddsHistorySportsbook, setSelectedOddsHistorySportsbook] = useState();
	const [selectedDumbBook, setSelectedDumbBook] = useState();

	const [selectedOddsHistoryXAxis, setSelectedOddsHistoryXAxis] = useState();
	const [selectedOddsHistoryYAxis, setSelectedOddsHistoryYAxis] = useState();
	const [selectedOddsHistoryXAxisUnder, setSelectedOddsHistoryXAxisUnder] = useState();
	const [selectedOddsHistoryYAxisUnder, setSelectedOddsHistoryYAxisUnder] = useState();
	const [selectedBooksIncludedInGraph, setSelectedBooksIncludedInGraph] = useState([]);

	const loginData = localStorage.getItem('loginData');

	const [queryName, setQueryName] = useState(JSON.parse(loginData).queries?.[0]?.[0]?.name);

	const [isSaved, setIsSaved] = useState(false);

	const [isLiveFilter, setIsLiveFilter] = useState(false);

	const [discordUsername, setDiscordUsername] = useState(loginData?.discord_username);
	const [isDiscordVerified, setIsDiscordVerified] = useState(loginData?.discord_verified);
	const [isDiscordActive, setIsDiscordActive] = useState(loginData?.discord_feeds_active);

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('ev');
	const visibleRows = useMemo(() => evData?.sort(getComparator(order, orderBy), [evData, order, orderBy]).slice(0, 150));
	const [lineCharts, setLineCharts] = useState(false);

	const scrollTo = () => {
		scroller.scrollTo("collapse-row", {
			duration: 700,
			delay: 0,
			smooth: "easeInOutQuart",
			offset: -190
		});
	};

	useEffect(() => {
		const getQueryMetadata = async (rows) => {
			// get list of possible sports, leagues, books and markets
			const data = await getCommonData(username, password);
			setFilterOptions(data);
			const loginData = localStorage.getItem('loginData');
			// console.log('loginData: ', JSON.parse(loginData));
			// console.log(JSON.parse(loginData).queries);
			setDiscordUsername(JSON.parse(loginData).discord_username);
			setIsDiscordVerified(JSON.parse(loginData).discord_verified);
			setIsDiscordActive(JSON.parse(loginData).discord_feeds_active);

			const initialQuery = rows ? rows : JSON.parse(loginData).queries[0];

			setIsLoading(true)
			const evDataRes = await runEvScan(username, password, initialQuery, isLiveFilter);
			setEvData(evDataRes);
			setIsLoading(false)
			setIsGamesLoading(true);
		};
		if (!filterOptions && !queryRows.length) {
			const loginData = localStorage.getItem('loginData');
			// console.log('🚀 ~ useEffect ~ loginData:', JSON.parse(loginData));
			if (!JSON.parse(loginData)?.queries?.length) {
				setQueryRows([
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-1000, 1000],
						minMaxEv: [1, 100],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'Caesars', label: 'Caesars' },
							{ value: 'BetMGM', label: 'BetMGM' },
							{ value: 'FanDuel', label: 'FanDuel' },
						],
						sharp: [
							{ value: 'Pinnacle', label: 'Pinnacle' },
							{ value: 'Circa Vegas', label: 'Circa Vegas' },
						],
						market: [],
						marketType: [
							{ value: 'Main Markets', label: 'Main Markets' },
							{ value: 'Alternate Markets', label: 'Alternate Markets' },
						],
						name: 'Default EV Scan',
					},
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-1000, 1000],
						minMaxEv: [3, 100],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'BetMGM', label: 'BetMGM' },
						],
						sharp: [{ value: 'FanDuel', label: 'FanDuel' }],
						market: [],
						marketType: [{ value: 'Player Props', label: 'Player Props' }],
						name: 'Default EV Scan',
					},
				]);
				getQueryMetadata([
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-1000, 1000],
						minMaxEv: [1, 100],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'Caesars', label: 'Caesars' },
							{ value: 'BetMGM', label: 'BetMGM' },
							{ value: 'FanDuel', label: 'FanDuel' },
						],
						sharp: [
							{ value: 'Pinnacle', label: 'Pinnacle' },
							{ value: 'Circa Vegas', label: 'Circa Vegas' },
						],
						market: [],
						marketType: [
							{ value: 'Main Markets', label: 'Main Markets' },
							{ value: 'Alternate Markets', label: 'Alternate Markets' },
						],
						name: 'Default EV Scan',
					},
					{
						league: [],
						date: new Date(new Date().setDate(new Date().getDate() + 30)),
						minMaxOdds: [-1000, 1000],
						minMaxEv: [3, 100],
						minMaxEvFilterOpen: false,
						minMaxOddsFilterOpen: false,
						sport: [],
						book: [
							{ value: 'bet365', label: 'bet365' },
							{ value: 'DraftKings', label: 'DraftKings' },
							{ value: 'BetMGM', label: 'BetMGM' },
						],
						sharp: [{ value: 'FanDuel', label: 'FanDuel' }],
						market: [],
						marketType: [{ value: 'Player Props', label: 'Player Props' }],
						name: 'Default EV Scan',
					},
				]);
			} else {
				// find the default query
				let defaultQuery;

				for (const query of JSON.parse(loginData).queries) {
					if (query.default) {
						defaultQuery = query
					}
				}
				if (!defaultQuery) {
					defaultQuery = JSON.parse(loginData).queries[0]
				}

				setQueryRows(defaultQuery);
				setQueries(JSON.parse(loginData).queries);
				getQueryMetadata();
			}
		}
	});
	// console.log('query rows: ', queryRows);
	useEffect(() => {
		const getRelatedOddsData = async () => {
			if (selectedRow) {
				// @ts-ignore
				const outcome_id_normalized_no_book = selectedRow.outcome_id_normalized_no_book;
				const res = await getRelatedOdds(username, password, outcome_id_normalized_no_book);

				setSelectedRowData(res);
				setSelectedDumbBook(selectedRow.sportsbook);
				// setIsModalOpen(true)
				setIsModalLoading(false);
			} else {
				setSelectedRowData([]);
			}
		};

		getRelatedOddsData();
	}, [selectedRow, username, password]);

	const getEvData = async () => {
		if (!isLoading) {
			setIsLoading(true);
			setIsGamesLoading(true);
			const evDataRes = await runEvScan(username, password, selectedQuery ? selectedQuery : queryRows, isLiveFilter);
			setEvData(evDataRes);
			setIsLoading(false);
		}

	};

	const saveSettings = async () => {
		await savePreferences(username, password, selectedQuery ? selectedQuery : queryRows, selectedQuery ? selectedQuery[0].name : queryName);
		setIsSaved(true);
	};

	const saveNotificationSettings = async () => {
		await saveNotificationPreferences(username, password, selectedQuery ? selectedQuery : queryRows, selectedQuery ? selectedQuery[0].name : queryName);
		setIsSaved(true);
	};

	const verifyDiscordLocal = async () => {
		await verifyDiscord(username, password, discordUsername);
	};

	useEffect(() => {
		const updateDiscordFeedActive = async () => {
			// console.log('isDiscordActive: ', isDiscordActive);
			await toggleDiscordNotifications(username, password, isDiscordActive);
			// const res = await axios.post(`${backendUrl}/preferences_feeds_active?discord_feeds_active=${isDiscordActive}&username=${username}&password=${password}`);
		};
		updateDiscordFeedActive();
	}, [isDiscordActive, username, password]);

	useEffect(() => {
		setTimeout(function () {
			setIsSaved(false);
		}, 5000);
	}, [isSaved]);

	useEffect(() => {
		// get game data and repopulate the evData variable
		const updateEvDataWithGamesAndLinks = async () => {
			// and update with limits
			const gameIds = [];
			const outcomeIds = [];
			const outcomeIdsSharps = [];
			for (const play of evData) {
				gameIds.push(play.game_id);
				outcomeIds.push(play.outcome_id_normalized);
				const outcomeIdNormalizedArr = play.outcome_id_normalized.split(':');
				const outcomeIdNormalizedSharp = `${outcomeIdNormalizedArr[0]}:${outcomeIdNormalizedArr[1]}:${outcomeIdNormalizedArr[2]}:${outcomeIdNormalizedArr[3]}:${play.sportsbook_sharp?.split(
					', '
				)}`;
				outcomeIdsSharps.push(outcomeIdNormalizedSharp);
			}

			const gamesRes = await getGamesData(username, password, gameIds);

			const evDataCopy = JSON.parse(JSON.stringify(evData));

			for (const play of evDataCopy) {
				play['game'] = gamesRes.games.find((game) => game.game_id === play.game_id);
			}
			setEvData(evDataCopy);

			const linksRes = await getLinksData(username, password, outcomeIds);
			const linksResSharps = await getLinksData(username, password, outcomeIdsSharps);

			for (const play of evDataCopy) {
				const outcomeIdNormalizedArr = play.outcome_id_normalized.split(':');
				const outcomeIdNormalizedSharp = `${outcomeIdNormalizedArr[0]}:${outcomeIdNormalizedArr[1]}:${outcomeIdNormalizedArr[2]}:${outcomeIdNormalizedArr[3]}:${play.sportsbook_sharp?.split(', ')[0]
					}`;
				const dumbdata = linksRes.find((game) => game.outcome_id_normalized === play.outcome_id_normalized);
				const sharpdata = linksResSharps.find((game) => game.outcome_id_normalized === outcomeIdNormalizedSharp);

				play['deep_link'] = dumbdata?.deep_link;
				play['deep_link_sharp'] = sharpdata?.deep_link;
				play['limit'] = dumbdata?.limits?.max;
				play['limit_sharp'] = sharpdata?.limits?.max;
				if (play.ev_over > play.ev_under) {
					play['bet_price_direction'] = getLineMovementDirection(dumbdata?.bet_price_over_history);
					play['bet_price_direction_sharp'] = getLineMovementDirection(sharpdata?.bet_price_over_history);
				} else {
					play['bet_price_direction'] = getLineMovementDirection(dumbdata?.bet_price_under_history);
					play['bet_price_direction_sharp'] = getLineMovementDirection(sharpdata?.bet_price_under_history);
				}
			}
			setEvData(evDataCopy);
			setIsGamesLoading(false);
		};
		if (evData.length && isGamesLoading) {
			updateEvDataWithGamesAndLinks();
		}
	}, [evData]);

	const [copyOfSelectedBooksIncludedInGraph, setCopyOfSelectedBooksIncludedInGraph] = useState([]);

	useEffect(() => {
		const getOddsHistory = async () => {
			// TODO: get the history for the selected sportsbook, then display
			//@ts-ignore

			if (selectedRow && (selectedRow?.sportsbook === selectedOddsHistorySportsbook || selectedOddsHistorySportsbook?.length > 1)) {
				// @ts-ignore
				const outcome_id_normalized_no_book = selectedRow.outcome_id_normalized_no_book;

				let selectedSportsbook = selectedRowData?.find((r) => r.sportsbook === selectedOddsHistorySportsbook);

				if (!selectedSportsbook) {
					selectedSportsbook = selectedRowData;
				}

				// setIsModalOpen(true)
				setIsModalLoading(false);

				if (selectedSportsbook?.length) {
					const xAxisValues = [];
					const yAxisValues = [];
					const xAxisValuesUnder = [];
					const yAxisValuesUnder = [];
					const booksIncludedInGraph = [];
					for (const book of selectedSportsbook) {
						const combinedComparableSportsbooks = [...topComparableSportsbooks, ...selectedBooksIncludedInGraph];
						if (combinedComparableSportsbooks.includes(book.sportsbook)) {
							xAxisValues.push(
								book?.bet_price_over_history?.map((x) => {
									return minutesSince(new Date(x.time).getTime());
								})
							);
							yAxisValues.push(
								book?.bet_price_over_history?.map((x) => {
									return x.price;
								})
							);
							xAxisValuesUnder.push(
								book?.bet_price_under_history?.map((x) => {
									return minutesSince(new Date(x.time).getTime());
								})
							);
							yAxisValuesUnder.push(
								book?.bet_price_under_history?.map((x) => {
									return x.price;
								})
							);
							booksIncludedInGraph.push(book.sportsbook);
						}
					}
					setSelectedOddsHistoryXAxis(xAxisValues);
					setSelectedOddsHistoryYAxis(yAxisValues);
					setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
					setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
					setSelectedBooksIncludedInGraph(booksIncludedInGraph);
					setCopyOfSelectedBooksIncludedInGraph(booksIncludedInGraph);
				} else {
					// @ts-ignore
					const xAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
						return minutesSince(new Date(x.time).getTime());
					});
					// @ts-ignore
					const yAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
						return x.price;
					});
					setSelectedOddsHistoryXAxis(xAxisValues);
					setSelectedOddsHistoryYAxis(yAxisValues);
					// @ts-ignore
					const xAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
						return minutesSince(new Date(x.time).getTime());
					});
					// @ts-ignore
					const yAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
						return x.price;
					});
					setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
					setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
				}
			} else if (selectedRow && selectedOddsHistorySportsbook && selectedRowData) {
				const selectedSportsbook = selectedRowData.find((r) => r.sportsbook === selectedOddsHistorySportsbook);

				// @ts-ignore
				const xAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
					return minutesSince(new Date(x.time).getTime());
				});
				// @ts-ignore
				const yAxisValues = selectedSportsbook?.bet_price_over_history?.map((x) => {
					return x.price;
				});
				setSelectedOddsHistoryXAxis(xAxisValues);
				setSelectedOddsHistoryYAxis(yAxisValues);

				// @ts-ignore
				const xAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
					return minutesSince(new Date(x.time).getTime());
				});

				// @ts-ignore
				const yAxisValuesUnder = selectedSportsbook?.bet_price_under_history?.map((x) => {
					return x.price;
				});
				setSelectedOddsHistoryXAxisUnder(xAxisValuesUnder);
				setSelectedOddsHistoryYAxisUnder(yAxisValuesUnder);
			}
		};

		// if (selectedBooksIncludedInGraph !== copyOfSelectedBooksIncludedInGraph || typeof selectedOddsHistorySportsbook === 'string') {
		// getOddsHistory();
		// }

		if (lineCharts && selectedRowData) {
			getOddsHistory()
		}
	}, [selectedOddsHistorySportsbook, selectedRow, selectedBooksIncludedInGraph, lineCharts, selectedRowData]);

	const allSportsbooks = selectedRowData.map((e) => {
		return {
			value: e.sportsbook,
			label: e.sportsbook,
		};
	});
	const selectedOddsHistoryYAxisUnderMapped = [];
	const selectedOddsHistoryYAxisMapped = [];
	const sportsbookColours = [];
	function getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	try {
		for (const y of selectedOddsHistoryYAxisUnder) {
			// selectedOddsHistoryYAxisUnderMapped.push(y?.map((e) => convertDecimalToAmerican(e).toFixed(0)));
			selectedOddsHistoryYAxisUnderMapped.push(y);
		}
		for (const y of selectedOddsHistoryYAxis) {
			// selectedOddsHistoryYAxisMapped.push(y?.map((e) => convertDecimalToAmerican(e).toFixed(0)));
			selectedOddsHistoryYAxisMapped.push(y);
			sportsbookColours.push(getRandomColor());
		}
	} catch (e) { }

	const xAxes = selectedOddsHistoryXAxis
		?.sort((a, b) => b.length - a.length)
		.map((e) => {
			return {
				data: e,
				label: 'Minutes ago',
				valueFormatter: (value) => value.toString(),
				reverse: true,
			};
		});

	const xAxesUnder = selectedOddsHistoryXAxisUnder
		?.sort((a, b) => b.length - a.length)
		.map((e) => {
			return {
				data: e,
				label: 'Minutes ago',
				valueFormatter: (value) => value.toString(),
				reverse: true,
			};
		});

	const yAxes = selectedOddsHistoryYAxisMapped
		?.sort((a, b) => b.length - a.length)
		.map((e, i) => {
			return {
				data: e,
				color: sportsbookColours[i],
				label: `Price | ${selectedBooksIncludedInGraph[i]}`,
				valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
				curve: 'linear',
			};
		});

	const yAxesUnder = selectedOddsHistoryYAxisUnderMapped
		?.sort((a, b) => b.length - a.length)
		.map((e, i) => {
			return {
				data: e,
				color: sportsbookColours[i],
				label: `Price | ${selectedBooksIncludedInGraph[i]}`,
				valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
				curve: 'linear',
			};
		});

	return (
		<div style={{ backgroundColor: '#485B79', height: evData.length ? '100%' : '100vh', color: 'white' }}>
			<EvScannerBar />
			<QueryBar
				getEvData={getEvData}
				setIsFiltersOpen={setIsFiltersOpen}
				isFiltersOpen={isFiltersOpen || isNotificationFiltersOpen}
				isNotificationsOpen={isNotificationsOpen}
				setIsNotificationsOpen={setIsNotificationsOpen}
				queryName={queryName}
				setQueryName={setQueryName}
				queryRows={queryRows}
				setQueryRows={setQueryRows}
				isLiveFilter={isLiveFilter}
				setIsLiveFilter={setIsLiveFilter}
				queries={queries}
				setQueries={setQueries}
				setSelectedQuery={setSelectedQuery}
				selectedQuery={selectedQuery}
			/>
			{isSaved && (
				<div style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '-2rem' }}>
					<Alert severity="success">Settings saved!</Alert>
				</div>
			)}
			{isFiltersOpen && filterOptions && (
				<FiltersBar
					filterOptions={filterOptions}
					isOpen={isFiltersOpen}
					setIsOpen={setIsFiltersOpen}
					queryRows={queryRows}
					selectedQuery={selectedQuery}
					setQueryRows={setQueryRows}
					saveSettings={saveSettings}
					setSelectedQuery={setSelectedQuery}
					setIsNotificationFiltersOpen={setIsNotificationFiltersOpen}
					setIsFiltersOpen={setIsFiltersOpen}
				/>
			)}
			<Modal
				open={isNotificationsOpen}
				onClose={() => {
					setIsNotificationsOpen(false);
				}}>
				<div>
					<NotificationsModal
						verifyDiscordLocal={verifyDiscordLocal}
						setDiscordUsername={setDiscordUsername}
						discordUsername={discordUsername}
						isDiscordVerified={isDiscordVerified}
						isDiscordActive={isDiscordActive}
						setIsDiscordActive={setIsDiscordActive}
					/>
				</div>
			</Modal>
			{isNotificationFiltersOpen && filterOptions && (
				<NotificationsBar
					filterOptions={filterOptions}
					isOpen={isFiltersOpen}
					setIsOpen={setIsFiltersOpen}
					queryRows={queryRows}
					setQueryRows={setQueryRows}
					saveSettings={saveSettings}
					discordUsername={discordUsername}
					setDiscordUsername={setDiscordUsername}
					isDiscordVerified={isDiscordVerified}
					setIsDiscordVerified={setIsDiscordVerified}
					isDiscordActive={isDiscordActive}
					setIsDiscordActive={setIsDiscordActive}
					setIsNotificationFiltersOpen={setIsNotificationFiltersOpen}
					setIsFiltersOpen={setIsFiltersOpen}
					saveNotificationSettings={saveNotificationSettings}
				/>
			)}
			<div style={{ display: 'flex' }}>
				<div style={{ marginTop: '2rem', marginLeft: '2rem', marginRight: '2rem', width: '100%' }}>
					<Table>
						<TableHead style={{ backgroundColor: '#0f1b33' }}>
							<TableRow>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', borderTopLeftRadius: '12px' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'market' ? order : 'asc'}
										onClick={() => {
											setOrderBy('market');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Market
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'bet_name' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_name');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Bet
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', maxWidth: '50px' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sportsbook' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sportsbook');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Book
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }} sortDirection="asc">
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'bet_price' ? order : 'asc'}
										onClick={() => {
											setOrderBy('bet_price');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Price
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sharps' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sharps');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Sharp
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'sharp_prices' ? order : 'asc'}
										onClick={() => {
											setOrderBy('sharp_prices');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Sharp Price
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									Game
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'start_date' ? order : 'asc'}
										onClick={() => {
											setOrderBy('start_date');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Starts
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'ev' ? order : 'asc'}
										onClick={() => {
											setOrderBy('ev');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										EV
									</TableSortLabel>
								</TableCell>
								<TableCell width={50} style={{ color: 'white', cursor: 'pointer', fontWeight: 'bold', borderTopRightRadius: '12px' }}>
									<TableSortLabel
										style={{ color: 'white' }}
										direction={orderBy === 'ev' ? order : 'asc'}
										onClick={() => {
											setOrderBy('quarter_kelly');
											setOrder(order === 'asc' ? 'desc' : 'asc');
										}}>
										Quarter Kelly
									</TableSortLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						{!isLoading && (
							<TableBody>
								{visibleRows?.map((row, index) => {
									return (
										<>
											<TableRow
												style={{ backgroundColor: index % 2 === 0 ? whiteShaded : '#e6e7ec' }}
												className="cursor-pointer"
												key={'temp'}
												onClick={() => {
													setIsModalLoading(true);
													// @ts-ignore
													if (selectedRow?.id === row.id) {
														setSelectedRow(undefined);
														setLineCharts(false)
													} else {
														setSelectedRow(row);
														setSelectedOddsHistorySportsbook(row.sportsbook);
														setLineCharts(true)
													}
													// setIsModalOpen(true)
												}}
												sx={{
													'&:hover': {
														backgroundColor: '#cbcbcb !important',
													},
												}}>
												<TableCell className="text-black">{row.bet_type}</TableCell>
												<TableCell className="text-black">{row.bet_name}</TableCell>
												<TableCell className="text-black">
													{/* logo generator, input is sportsbook */}
													<LogoGenerator sportsbook={row.sportsbook_dumb} />
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.deep_link?.desktop && (
														<>
															<a
																target="_new"
																href={row.deep_link?.desktop?.replace(/<STATE>./g, '')}
																style={{
																	textDecoration: 'none',
																	color: '#1a0540',
																	display: 'block',
																	width: 'min-content',
																}}>
																<div
																	style={{
																		backgroundColor: '#dd8d13',
																		width: 'fit-content',
																		padding: '4px',
																		borderRadius: '8px',
																		opacity: 0.85,
																	}}
																	className="bet-button">
																	<div style={{ display: 'flex', flexDirection: 'row' }}>
																		<LinkIcon style={{ width: '15px', textAlign: 'center' }} />
																		<span style={{ alignSelf: 'center', marginLeft: '2px' }}>Bet!</span>
																	</div>
																</div>
															</a>
														</>
													)}
												</TableCell>
												<TableCell className="text-black">
													<div className="flex flex-row">
														<BetPrice price={row.bet_price} direction={row.bet_price_direction} limit={row.limit} />
													</div>
												</TableCell>
												<TableCell className="text-black" width={50}>
													{/* logo generator, input is sportsbook */}
													<div style={{ display: 'grid', gridTemplateColumns: 'auto auto', width: 'min-content' }}>
														{row.sportsbook_sharp?.split(', ').map((e) => (
															<LogoGenerator sportsbook={e} />
														))}
													</div>

													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.deep_link_sharp?.desktop && (
														<a
															target="_new"
															href={row.deep_link_sharp?.desktop?.replace(/<STATE>./g, '')}
															style={{
																textDecoration: 'none',
																color: '#1a0540',
																display: 'block',
																width: 'min-content',
															}}>
															<div
																style={{
																	backgroundColor: '#dd8d13',
																	width: 'fit-content',
																	padding: '4px',
																	borderRadius: '8px',
																	opacity: 0.85,
																}}
																className="bet-button">
																<div style={{ display: 'flex', flexDirection: 'row' }}>
																	<LinkIcon style={{ width: '15px', textAlign: 'center' }} />
																	<span style={{ alignSelf: 'center', marginLeft: '2px' }}>Bet!</span>
																</div>
															</div>
														</a>
													)}
												</TableCell>
												<TableCell className="text-black">
													<div className="flex flex-row">
														<p className="mt-3">
															<BetPrice price={row.sharp_prices} direction={row.bet_price_direction_sharp} limit={row.limit_sharp} />
															{/* {row.sharp_prices} */}
														</p>
														{/* {
                                                    (row.bet_price_direction_y.over === 1 || row.bet_price_direction_y.under == 1) && <ArrowUpwardIcon className="mt-3 ml-3" />
                                                }
                                                {
                                                    (row.bet_price_direction_y.over === -1 || row.bet_price_direction_y.under == -1) && <ArrowDownwardIcon className="mt-3 ml-3" />
                                                }
                                                {
                                                    (row.bet_price_direction_y.over === 0 || row.bet_price_direction_y.under == 0) && <HorizontalRuleIcon className="mt-3 ml-3" />
                                                } */}
													</div>
												</TableCell>
												<TableCell className="text-black">
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.game && (
														<>
															{row.game?.away_team_display} @ {row.game?.home_team_display}
														</>
													)}
												</TableCell>
												<TableCell className="text-black">
													{isGamesLoading && <CircularProgress />}
													{!isGamesLoading && row.game && (
														<>
															{new Date(row?.game?.start_date).toDateString()} @ {new Date(row?.game?.start_date).toLocaleTimeString('en-us')}
														</>
													)}
												</TableCell>
												<TableCell className="text-black">{(Number(row.ev) * 100).toFixed(2)}%</TableCell>
												<TableCell className="text-black">
													<Tooltip title={`$${(row.quarter_kelly?.toFixed(3) * (bankroll / 100)).toFixed(1)}`}>
														<div>{Number(row.quarter_kelly).toFixed(2)}u</div>
													</Tooltip>
												</TableCell>
											</TableRow>
											{lineCharts &&
												selectedRowData.length &&
												(selectedOddsHistoryYAxis || selectedOddsHistoryYAxisUnder) &&
												selectedRow?.id === row.id &&
												typeof selectedOddsHistoryXAxis?.[0] !== 'object' ? (
												<TableRow>
													<TableCell colSpan={12} sx={{ padding: '0px !important' }}>
														<Collapse in={true} className='collapse-row' unmountOnExit>
															<div style={{ backgroundColor: index % 2 === 0 ? whiteShaded : '#e6e7ec', overflowX: 'auto', textAlign: 'center', overflowY: 'hidden' }} >
																<div
																	style={{
																		display: 'flex',
																		// backgroundColor: 'white',
																		// marginTop: '10vh',
																		// marginLeft: '4rem',
																		// marginRight: '4rem',
																		borderRadius: '10px',
																		paddingTop: '10px',
																	}}>
																	<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
																		<div style={{ display: 'flex' }}>
																			<LogoGenerator sportsbook={selectedOddsHistorySportsbook} />
																			<div style={{ marginLeft: '1rem' }}>
																				{selectedRowData[0].selection} over {selectedRowData[0].selection_points} {selectedRowData[0].market}
																			</div>
																		</div>
																		<LineChart
																			xAxis={[
																				{
																					// @ts-ignore
																					data: selectedOddsHistoryXAxis || [],
																					label: 'Minutes ago',
																					valueFormatter: (value) => value.toString(),
																					reverse: true,
																					// hideTooltip: true,
																				},
																			]}
																			series={[
																				{
																					data: selectedOddsHistoryYAxis || [],
																					color: '#0f1b33',
																					label: 'Price',
																					valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
																					curve: 'linear',
																				},
																			]}
																			axisHighlight={{ x: 'none', y: 'none' }}
																			tooltip={{ trigger: 'item' }}
																			yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																			width={400}
																			height={300}
																			slotProps={{ legend: { hidden: true } }}
																			sx={{
																				'& .MuiChartsAxis-label': {
																					// padding: "10px !important",
																					// fill: "white !important",
																					marginRight: '20px',
																				},
																				'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																					transform: 'translateX(-12px) !important',
																				},
																			}}
																		/>
																	</div>
																	<div style={{ width: '40%', borderRadius: '10px' }}>
																		<div style={{ display: 'flex' }}>
																			<div
																				style={{
																					marginRight: '1rem',
																				}}>
																				<LogoGenerator sportsbook={selectedOddsHistorySportsbook} />
																			</div>
																			{selectedRowData[0].selection} under {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>
																		<LineChart
																			xAxis={[
																				{
																					// @ts-ignore
																					data: selectedOddsHistoryXAxisUnder || [],
																					label: 'Minutes ago',
																					valueFormatter: (value) => value.toString(),
																					reverse: true,
																					// hideTooltip: true,
																				},
																			]}
																			series={[
																				{
																					data: selectedOddsHistoryYAxisUnder || [],
																					color: '#0f1b33',
																					label: 'Price',
																					valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0).toString(),
																					curve: 'linear',
																				},
																			]}
																			axisHighlight={{ x: 'none', y: 'none' }}
																			tooltip={{ trigger: 'item' }}
																			yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																			width={400}
																			height={300}
																			slotProps={{ legend: { hidden: true } }}
																			sx={{
																				'& .MuiChartsAxis-label': {
																					// padding: "10px !important",
																					// fill: "white !important",
																					marginRight: '20px',
																				},
																				'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																					transform: 'translateX(-12px) !important',
																				},
																			}}
																		/>
																	</div>
																</div>
															</div>
														</Collapse>
													</TableCell>
												</TableRow>
											) : (
												<></>
											)}
											{lineCharts &&
												selectedRowData.length &&
												(selectedOddsHistoryYAxis || selectedOddsHistoryYAxisUnder) &&
												selectedRow?.id === row.id &&
												typeof selectedOddsHistoryXAxis?.[0] === 'object' ? (
												<TableRow>
													<TableCell colSpan={12} sx={{ padding: '0px !important' }}>
														<Collapse className='collapse-row' in={true} unmountOnExit>
															<div style={{ backgroundColor: index % 2 === 0 ? whiteShaded : '#e6e7ec', overflowX: 'auto', textAlign: 'center', overflowY: 'hidden' }}>
																<div
																	style={{
																		display: 'flex',
																		backgroundColor: 'white',
																		// marginTop: '10vh',
																		// marginLeft: '4rem',
																		// marginRight: '4rem',
																		borderRadius: '10px',
																		// paddingTop: '10px',
																	}}>
																	<div style={{ maxWidth: '190px', marginLeft: '1rem', textAlign: 'left' }}>
																		Select books
																		<Select
																			options={allSportsbooks}
																			isMulti
																			components={animatedComponents}
																			placeholder="sportsbooks"
																			onChange={(e) => {
																				const sportsbooks = e.map((y) => y.value);

																				const copy = [...new Set(JSON.parse(JSON.stringify([...selectedBooksIncludedInGraph, ...sportsbooks])))];

																				setSelectedBooksIncludedInGraph(copy);
																			}}
																		/>
																	</div>
																	<div style={{ width: '40%', margin: 'auto', borderRadius: '10px' }}>
																		<div style={{ marginLeft: '1rem' }}>
																			{selectedRowData[0].selection} over {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>

																		<div style={{ display: 'flex', marginTop: '1rem' }}>
																			{selectedBooksIncludedInGraph?.map((e, i) => (
																				<div style={{ marginRight: '8px' }}>
																					<LogoGenerator sportsbook={e} />
																					<div style={{ height: '2px', backgroundColor: sportsbookColours[i] }} />
																				</div>
																			))}
																		</div>
																		<LineChart
																			xAxis={xAxes}
																			series={yAxes}
																			axisHighlight={{ x: 'none', y: 'none' }}
																			tooltip={{ trigger: 'item' }}
																			yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																			width={400}
																			height={300}
																			slotProps={{ legend: { hidden: true } }}
																			sx={{
																				'& .MuiChartsAxis-label': {
																					// padding: "10px !important",
																					// fill: "white !important",
																					marginRight: '20px',
																				},
																				'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																					transform: 'translateX(-12px) !important',
																				},
																			}}
																		/>
																	</div>
																	<div style={{ backgroundColor: 'white', width: '40%', borderRadius: '10px' }}>
																		<div style={{ marginLeft: '1rem' }}>
																			{selectedRowData[0].selection} under {selectedRowData[0].selection_points} {selectedRowData[0].market}
																		</div>

																		<div style={{ display: 'flex', marginTop: '1rem' }}>
																			{selectedBooksIncludedInGraph?.map((e, i) => (
																				<div style={{ marginRight: '8px' }}>
																					<LogoGenerator sportsbook={e} />
																					<div style={{ height: '2px', backgroundColor: sportsbookColours[i] }} />
																				</div>
																			))}
																		</div>
																		<LineChart
																			xAxis={xAxesUnder}
																			series={yAxesUnder}
																			axisHighlight={{ x: 'none', y: 'none' }}
																			tooltip={{ trigger: 'item' }}
																			yAxis={[{ label: 'Price', valueFormatter: (value) => convertDecimalToAmerican(value).toFixed(0) }]}
																			width={400}
																			height={300}
																			slotProps={{ legend: { hidden: true } }}
																			sx={{
																				'& .MuiChartsAxis-label': {
																					// padding: "10px !important",
																					// fill: "white !important",
																					marginRight: '20px',
																				},
																				'& .MuiChartsAxis-directionY .MuiChartsAxis-label': {
																					transform: 'translateX(-12px) !important',
																				},
																			}}
																		/>
																	</div>
																</div>
															</div>
														</Collapse>
													</TableCell>
												</TableRow>
											) : (
												<></>
											)}
										</>
									);
								})}
							</TableBody>
						)}
					</Table>
					{isLoading && (
						<div style={{ textAlign: 'center', marginTop: '4rem', height: '100vh', position: 'sticky', top: 0 }}>
							<GridLoader />
						</div>
					)}
				</div>
				{selectedRowData.length ? (
					<div style={{ marginTop: '2rem', marginRight: '2rem', width: '100%' }}>
						<Sidebar
							data={selectedRowData}
							oddsHistoryData={{ selectedOddsHistoryXAxis, selectedOddsHistoryYAxis, selectedOddsHistoryXAxisUnder, selectedOddsHistoryYAxisUnder }}
							setSelectedOddsHistorySportsbook={setSelectedOddsHistorySportsbook}
							selectedOddsHistorySportsbook={selectedOddsHistorySportsbook}
							selectedBooksIncludedInGraph={selectedBooksIncludedInGraph}
							setSelectedBooksIncludedInGraph={setSelectedBooksIncludedInGraph}
							selectedDumbBook={selectedDumbBook}
							lineCharts={lineCharts}
							setLineCharts={setLineCharts}
							scrollTo={scrollTo}
						/>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
