import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const login = async (username, password) => {
    // login and store username and password to local storage if successful
    const lgin = await axios.post(`${backendUrl}/login?username=${username}&password=${password}`);
    
    localStorage.setItem("username", username);
    localStorage.setItem("password", password);
    localStorage.setItem("_id", lgin.data._id);
    localStorage.setItem("loginData", JSON.stringify(lgin.data))
    return lgin;
}