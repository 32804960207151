import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Tooltip } from '@mui/material';

export const BetPrice = (props) => {
	const price = isNaN(props.price) && !props.price.includes('/') ? '' : props.price;
	const { direction, limit } = props;
	// console.log('limit: ', limit);
	return (
		<div>
			<div style={{ border: '1px black solid', borderRadius: '12px', padding: '12px', textAlign: 'center', width: '66px' }}>
				{price}
				<span style={{ position: 'relative', top: '7px', marginLeft: '5px' }}>
					{direction === 1 && (
						<Tooltip title="trending upwards">
							<NorthIcon />
						</Tooltip>
					)}
					{direction === -1 && (
						<Tooltip title="trending downwards">
							<SouthIcon />
						</Tooltip>
					)}
					{direction === 0 && (
						<Tooltip title="trending even">
							<HorizontalRuleIcon />
						</Tooltip>
					)}
				</span>
			</div>
            {
                limit && <div style={{ textAlign: 'right' }}>Limit: {limit}$</div>

            }
		</div>
	);
};
