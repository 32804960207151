import axios from "axios";

export const backendUrl = process.env.REACT_APP_BACKEND_URL


export const getLinksData = async (username, password, outcome_ids) => {
    try {
        const linksData = (await axios.post(`${backendUrl}/getLinks?username=${username}&password=${password}`, {
            outcome_id_normalized: outcome_ids
        })).data;
    
        return linksData;
    } catch(e) {
        return []
    }
    
}