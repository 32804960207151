export const EvScannerBar = () => {
    return <div style={{ display: 'flex', flexDirection: "row", marginTop: "1rem" }}>
        <h1 style={{ marginTop: 'auto', marginLeft: "2rem" }}>
          EV Scanner
        </h1>
        <div style={{ marginLeft: 'auto', marginRight: "2rem" }}>
          <h3>
            Bankroll
          </h3>
        </div>
      </div>
}