import { Verified } from '@mui/icons-material';
import { Button, styled, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: '#1890ff',
				...theme.applyStyles('dark', {
					backgroundColor: '#177ddc',
				}),
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
		...theme.applyStyles('dark', {
			backgroundColor: 'rgba(255,255,255,.35)',
		}),
	},
}));

export const NotificationsModal = (props) => {
	const { verifyDiscordLocal, setDiscordUsername, discordUsername, isDiscordVerified, isDiscordActive, setIsDiscordActive } = props;
	console.log('isDiscordVerified: ', isDiscordVerified, isDiscordActive);
	const [isVerifiedClicked, setIsVerifiedClicked] = useState(false);
	return (
		<div
			style={{
				marginLeft: 'auto',
				backgroundColor: '#f1f1f3',
				marginRight: 'auto',
				paddingTop: '1.5rem',
				// display: 'flex',
				textAlign: 'center',
				borderRadius: '12px',
				padding: '12px',
				marginTop: '10vh',
				width: '30vw',
			}}>
			<div style={{ fontSize: '26px' }}>EV Scanner Notifications</div>
			<div>
				<div style={{ marginLeft: '1.5rem', color: '#0f1b33', marginTop: '2rem', placeSelf: 'center', display: 'flex' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div>Enter your Discord ID</div>
						<TextField
							placeholder="Discord ID"
							variant="outlined"
							onChange={(e) => {
								setDiscordUsername(e.target.value);
							}}
							value={discordUsername}
							style={{ marginTop: '1.5rem', maxWidth: '300px' }}
						/>
					</div>
					{isDiscordVerified && (
						<Tooltip title="Discord ID has been verified">
							<Verified style={{ marginTop: 'auto', marginBottom: "auto", marginLeft: '1rem', color: '#0f1b33' }} />
						</Tooltip>
					)}
				</div>

				{!isDiscordVerified && (
					<Button
						onClick={async () => {
							setIsVerifiedClicked(true);
							verifyDiscordLocal();
						}}
						style={{ marginLeft: '2rem', marginBottom: 'auto' }}>
						Verify Discord Username
					</Button>
				)}
				{isVerifiedClicked && (
					<div>
						<br />
						After verifying your username on Discord, refresh this page to finish configuring notifications.
						<br />
						<br />
						{'To configure, click on Filters -> Notifications'}
					</div>
				)}
				{isDiscordVerified && (
					<div style={{ color: 'black', justifyContent: 'center', marginTop: '2rem', display: 'flex' }}>
						<div
							style={{ color: 'black', display: 'flex', cursor: 'pointer' }}
							onClick={(e) => {
								setIsDiscordActive(!isDiscordActive);
							}}>
							<Typography>Off</Typography>
							<AntSwitch checked={isDiscordActive} inputProps={{ 'aria-label': 'ant design' }} />
							<Typography>On</Typography>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
